import { createTheme } from "@mui/material";
export const colors = {
  white: "#fff",
  blackColor: "#000",
  darkBgButton: '#232326',
  lightBgButton: '#EFEFF2',
  error: '#F44336',
  info: '#C1C1C9',
  gray: '#6A6A71',
  toastError: '#aa2e25',
  lightBlue: '#2FABE1',
  lightRed: '#E03C23',
  darkYellow: '#F59E0B',
  lightGray: '#E0E0E4'
}
declare module "@mui/material/styles" {
  interface Theme {
    customBorders: {
      50: string;
      100: string;
      200: string;
    };
  }
  interface ThemeOptions {
    customBorders?: {
      50?: string;
      100?: string;
      200?: string;
    };
  }
}
export const theme = createTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: colors.white,
        },
        secondary: {
          main: colors.blackColor,
        },
        info: {
          main: colors.gray,
          50: colors.white
        },
        error: {
          main: colors.error
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: colors.blackColor,
        },
        secondary: {
          main: colors.white,
        },
        info: {
          main: colors.info,
          50: colors.darkBgButton,
        },
        error: {
          main: colors.error
        }
      },
    },
  },
  typography: {
    fontFamily: 'good_sansregular',
    fontWeightBold: 400,
    h1: {
      fontFamily: 'good_sansbold',
      fontSize: '34px',
    },
    h3: {
      fontFamily: 'good_sansbold',
      fontSize: '20px',
    },
    h4: {
      fontFamily: 'good_sansmedium',
      fontSize: '24px',
    },
    h5: {
      fontFamily: 'good_sansmedium',
      fontSize: '16px',
      fontWeightBold: 400,

    },
    h6: {
      fontSize: '14px',
      fontFamily: 'good_sansthin',
      letterSpacing: 0.25,
    },
    subtitle1: {
      fontSize: '12px',
      fontFamily: 'good_sansthin',
      lineHeight: 1.6
    },

  },

  shadows: [
    'none',
    '0px 0px 24px 0px #5C5C5C1F',
    '2.9px 4.35px 14.49px 0px',
    '0px 0px 2px 0px #00000005 inset, -6px -6px 16px 0px #FFFFFF0A, 6px 6px 16px 0px #00000040',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',

  ],
  customBorders: {
    50: "6px solid #1976d2",
    100: "2px dashed #ff5722",
    200: "2px solid rgba(0, 0, 0, 0.1)",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontFamily: 'good_sansbold',
          borderRadius: '50px'
        },
        text: {
          letterSpacing: 1.25
        }
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          padding: '44px',
          borderRadius: '16px',
          // background: '#232326',
          background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.white,
          minWidth: '400px',
          [theme.breakpoints.down('sm')]: {
            minWidth: '80%',
            maxWidth: '90%',
          }
        })
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          fontSize: '16px',
          fontFamily: 'good_sansmedium',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding:0,
          gap: '16px',
          '& .MuiButton-root': {
            boxShadow: '0px 0px 2px 0px #00000005 inset, -6px -6px 16px 0px #FFFFFF0A, 6px 6px 16px 0px #00000040',
            background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightBgButton,
            color: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
            fontSize: "14px",
            width: '100%',
            padding: '12px 0px',
            fontFamily: 'good_sansmedium',
          }
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightGray,
          color: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightGray,
        }),
      },
    },
  },
});

