import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { VerifyOtpStyleWrapper } from '../../pages/login/otpInput-style';

type ChangeHandler = (otpValue: string, length: number) => void;

interface IProps {
    length?: number,
    autoFocus?: boolean,
    type?: string,
    placeholder?: any,
    showText?: boolean,
    isValidOtp?: boolean
    onChange: ChangeHandler,
    invalidOtpText?: string
}

export const OtpInput = (props: IProps) => {
    const { invalidOtpText, length = 6, onChange, autoFocus = true, type = "text", placeholder = ["", "", "", "", "", ""], showText = true, isValidOtp } = props;
    const [otp, setOtp] = useState(Array(length).fill(''));
    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array.from({ length }, () => null));

    useEffect(() => {
        focusInput();
    }, []);

    useEffect(() => {
        if (!isValidOtp) {
            setOtp(Array(6).fill(''));
        }
    }, [isValidOtp])

    const focusInput = () => {
        if (inputRefs.current[0] && autoFocus) {
            inputRefs.current[0].focus();
        }
    };

    const handleInputChange = (value: string, index: number) => {
        const sanitizedValue = value.replace(/\D/g, '');
        const newOtp = [...otp];
        newOtp[index] = sanitizedValue;
        setOtp(newOtp);
        onChange(newOtp.join(''), length);
        if (sanitizedValue !== '' && index < length - 1) {
            inputRefs?.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (e: any, index: any) => {
        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text/plain');
        const pastedValues = pastedData.split('').slice(0, length);
        const newOtp = [...otp];
        pastedValues.forEach((value, i) => {
            if (inputRefs.current?.[index + i]) {
                newOtp[index + i] = value;
            }
        });
        setOtp(newOtp);
        onChange(newOtp.join(''), length);
    };
    return (
        <VerifyOtpStyleWrapper>
            <Box className="otpContainer" mb={2}>
                {otp.map((digit: number, index: number) => (
                    <Box key={index}>
                        <TextField
                            placeholder={placeholder[index]}
                            key={index}
                            type={showText ? "text" : type}
                            value={digit}
                            onChange={(e) => handleInputChange(e.target.value, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            inputRef={(ref) => (inputRefs.current[index] = ref)}
                            onPaste={(e: any) => handlePaste(e, index)}
                            variant="outlined"
                            autoComplete="off"
                            inputProps={{ maxLength: 1 }}
                        />
                    </Box>
                ))}
            </Box>
        </VerifyOtpStyleWrapper>
    );
};
