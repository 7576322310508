import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const ForgotPasswordValidationSchema = () => {
    const { t } = useTranslation();
    return Yup.object({
        email: Yup.string()
            .required(t('email_validation_text'))
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('email_invalid_validation_text')),
    });
};
