import React from 'react';
import { useNavigate } from 'react-router-dom';
import { INavigationConfig, NavigationConfig } from '../utils/navigation-constants';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openToast } from '../store/reducers/toastSlice';

const SocialLoginAuthCallback = () => {
    const navigate = useNavigate();
    const navConfig: INavigationConfig = NavigationConfig;
    const { t } = useTranslation();
    let dispatch = useDispatch();
    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const error = urlParams.get('error_description');
        const token = urlParams.get('token'); // Replace with actual response data key.
        console.log(error, token, '==> error and token');
        switch (error) {
            case 'PreSignUp failed with error user not found. ':
                dispatch(
                    openToast({
                        show: true,
                        message: t('pre_signup_error_message'),
                        severity: 'error',
                        isAlertOpen: true,
                    }),
                );
                navigate(navConfig.Login, { state: { error } });
                break;
            default:
        }
    }, [navigate]);

    return;
};

export default SocialLoginAuthCallback;
