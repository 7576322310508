export enum CognitoCallbacks {
    CONFIRM_SIGN_IN_WITH_SMS_CODE = 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
    CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
    DONE = 'DONE',
    CONFIRM_SIGN_IN_WITH_EMAIL_CODE = 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE',
    CONFIRM_RESET_PASSWORD_WITH_CODE = 'CONFIRM_RESET_PASSWORD_WITH_CODE',
}
export enum CognitoErrorCodes {
    NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
    USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
    CODE_MISMATCH_EXCEPTION = 'CodeMismatchException',
    SIGN_IN_EXCEPTION = 'SignInException',
    EMPTY_SIGNIN_USER_NAME = 'EmptySignInUsername',
    RESET_PASSWORD_EXCEPTION = 'RESET_PASSWORD_EXCEPTION',
}
