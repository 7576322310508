import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"

export const ProfileSetting = () => {
    const {t} = useTranslation()

    return (

        <Box>{t('profile_settings')}</Box>
    )
}