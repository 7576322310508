import { Box, styled } from "@mui/material";

export const LandingPageStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    height: '100dvh',
    '& .body-content': {
        width: 'calc(100% - 75px)',
        padding: theme.spacing(0, 2),
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        }
    }
}));
