import { revertAll } from "../actions";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserState {
    cognitoUser: any;
    authenticationDetails: any;
    number: any;
    accessToken: string;
    idToken: string;
    refreshToken: string;
    userDetails: any;
    isLoggedIn: boolean;
    selectedLang: string;
};

const initialState: IUserState = {
    cognitoUser: null,
    authenticationDetails: null,
    number: null,
    accessToken: '',
    idToken: '',
    refreshToken: '',
    userDetails: null,
    isLoggedIn: false,
    selectedLang: 'en'
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.idToken = action.payload.idToken;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        saveCognitoUserInfo: (state, action) => {
            state.cognitoUser = action.payload.cognitoUser;
        },
        saveUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
        updateLoginStatus: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        updateUserLanguage: (state, action: PayloadAction<string>) => {
            state.selectedLang = action.payload;
        }
    },
    extraReducers: builder => builder.addCase(revertAll, () => initialState),
});

export const { loginSuccess, saveCognitoUserInfo, saveUserDetails, updateLoginStatus, updateUserLanguage } = userSlice.actions;
export const userReducer =  userSlice.reducer;