import { Box, Slider, Stack, Typography, useTheme } from "@mui/material"
import { ListItemWrapper, SliderUI } from "./home-styles"
import { colors } from "../../theme"
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import React from "react";
import { useTranslation } from "react-i18next";
const marks = [
    { value: 0, label: 'GPU' },
    { value: 50, label: 'Hybrid' },
    { value: 100, label: 'Conventional' },
];

export const SystemMode = () => {
    const cstTheme = useTheme()
    const [value, setValue] = React.useState(50);
    const { t } = useTranslation()
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };
    return (
        <ListItemWrapper p={2} className='listItems' bgcolor={'info.50'} boxShadow={1} borderRadius={4} mt={2}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='h5' display={'flex'} alignItems={'center'} columnGap={1.5}><BoltOutlinedIcon /> {t('system_mode')}</Typography>
                <Typography variant='subtitle1' fontWeight={700} color={colors.darkYellow}>{value === 0 ? 'GPU' : value === 50 ? 'Hybrid' : 'Conventional'}</Typography>
            </Box>
            <Stack pt={3.5}>
                <SliderUI
                    value={value}
                    onChange={handleChange}
                    step={null}
                    marks={marks}
                    min={0}
                    max={100}
                    sx={{
                        width: '95%',
                        '& .MuiSlider-rail,& .MuiSlider-track': {
                            background: colors.blackColor
                        },
                        '& .MuiSlider-thumb': {
                            '&:after': {
                                background: 'linear-gradient(321.3deg, #0782DB 22.24%, #11A8FD 77.76%)',
                            },
                        },
                        '& .MuiSlider-mark': {
                            width: cstTheme.spacing(2.5),
                            height: cstTheme.spacing(2.5),
                            borderRadius: '50%',
                            background: colors.blackColor,
                            opacity: 1
                        }
                    }}
                />
            </Stack>
        </ListItemWrapper>
    )
}