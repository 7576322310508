import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"

export const NotificationSetting = () => {
    const {t} = useTranslation()

    return (

        <Box>{t('notification_settings')}</Box>
    )
}