import { Alert, alpha, Snackbar, styled } from "@mui/material"
import { colors } from "../../theme"

interface IContainerProps {
    open: boolean,
    message?: string,
    severity?: any,
    handleClose?: any,
    action?: any,
}
export const ToastUI = styled(Snackbar)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '30px',
        width:'65%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        '& .MuiAlert-action': {
            padding: '4px 6px 0 8px'
        },
        '&.MuiAlert-colorError': {
            background: alpha(colors.toastError, 0.8)
        }
    }
}))
export const Toast = (props: IContainerProps) => {
    const { open, message, severity, handleClose } = props;
    return (
        <>
            <ToastUI open={open} onClose={() => handleClose(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert
                    variant="filled"
                    severity={severity}
                    onClose={() => handleClose(false)}
                    sx={{ width: '100%' }}
                    icon={false}
                >
                    {message}
                </Alert>
            </ToastUI>
        </>
    )

}