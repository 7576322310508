import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { NavigationConfig } from '../utils/navigation-constants';
import { PageNotFound } from '../pages/page-not-found';
import { VerifyLoginOtp } from '../pages/login/login-otp';
import PasswordSetup from '../pages/login/password-setup';
import { Login } from '../pages/login/login';
import { ForgotPassword } from '../pages/login/forgot-password';

const Routing: React.FC = () => {
    const { isLoggedIn } = useSelector((state: RootState) => state.user);
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path={NavigationConfig.Login} element={<Login />} />
            <Route path={NavigationConfig.ForgotPassword} element={<ForgotPassword />} />
            <Route path={NavigationConfig.LoginOtpScreen} element={<VerifyLoginOtp />} />
            <Route path={NavigationConfig.PasswordSetup} element={<PasswordSetup />} />
            <Route path="*" element={!isLoggedIn ? <PageNotFound /> : <Navigate to={'/'} />} />
        </Routes>
    );
};
export { Routing };
