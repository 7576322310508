import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
interface Iprops {
    header?: string;
    children?: string;
    cancelBtnName?: string;
    AcceptBtnName?: string;
    modalOpen?: any;
    closePopup: any;
    acceptBtn: any;
}

export const CommonModal: React.FC<Iprops> = ({ header, children, cancelBtnName, AcceptBtnName, modalOpen, closePopup, acceptBtn }) => {
    return (
        <Dialog
            open={modalOpen}
            onClose={closePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {header}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closePopup}>{cancelBtnName}</Button>
                <Button onClick={acceptBtn} autoFocus>
                    {AcceptBtnName}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
