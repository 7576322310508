import { Box, Grid2, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { ListItemWrapper, StyleWrapper } from './home-styles';
import MemoryIcon from '@mui/icons-material/Memory';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import WaterDamageOutlinedIcon from '@mui/icons-material/WaterDamageOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import { SystemMode } from './system-mode';
import { Temparature } from './temparature';
const assetLoader = require("../../assets/images/heater.png");

interface HomeProps {

}
const listBlocks = (title: string, monthValue: string, lifeValue: string, iconValue: any) => {
    return (
        <ListItemWrapper p={2} className='listItems' bgcolor={'info.50'} boxShadow={1} borderRadius={4} mt={2}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='h5' display={'flex'} alignItems={'center'} columnGap={1.5}>{iconValue} {title}</Typography>
                <KeyboardDoubleArrowRightIcon />
            </Box>
            <Stack pt={2} direction={'row'} justifyContent={'space-between'}>
                <Box textAlign={'start'}>
                    <Typography variant='subtitle1' pb={1}>This Month</Typography>
                    <Typography variant='h3'>{monthValue}</Typography>
                </Box>
                <Box textAlign={'end'}>
                    <Typography variant='subtitle1' pb={1}>Life Time</Typography>
                    <Typography variant='h3'>{lifeValue}</Typography>
                </Box>
            </Stack>
        </ListItemWrapper>
    )
}

export const Home: React.FC<HomeProps> = () => {
    const [showHeater, setShowHeater] = React.useState<boolean>(true)
    setTimeout(() => {
        setShowHeater(false)
    }, 3000)
    return (
        <StyleWrapper>
            <Box textAlign={'center'} className={`indexBody ${showHeater && 'indexBodyLoader'}`} pb={2}>
                <Grid2 container height={'100%'} >
                    {!showHeater && <Grid2 size={{ xs: 12, lg: 5 }} className='listBlock' paddingRight={{ xs: 0, lg: 2 }}>
                        {listBlocks('My Earnings', '$124', '$2535', <MemoryIcon />)}
                        {listBlocks('Water Usage', '45 Gallons', '1746 Gallons', <WaterDamageOutlinedIcon />)}
                        {listBlocks('GPU Up Time', '60 Hrs', '720 Hrs', <RestoreOutlinedIcon />)}
                        <SystemMode />
                        <Temparature />
                    </Grid2>}
                    <Grid2 size={showHeater ? 12 : 7} display={{ xs: 'none', lg: 'block' }} className='assetBlock'>
                        {showHeater && <Typography variant='h4'>Installation is pending ...</Typography>}
                        <Box />
                        {/* <Box m={'auto'} width={showHeater ? '500px' : '33%'}><img src={assetLoader} width={'100%'} /></Box> */}
                    </Grid2>
                </Grid2>
            </Box>

        </StyleWrapper>
    );
}