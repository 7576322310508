import { useEffect, useState } from 'react';
import { Box, Grid2, IconButton, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { INavigationConfig, NavigationConfig } from '../../utils/navigation-constants';
import { AppDispatch } from '../../store';
import { confirmResetPassword, confirmSignIn, signIn } from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { ButtonComponent } from '@watter/shared-components';
import { LoginUi } from './login-style';
import { colors } from '../../theme';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { hideLoader, setSession, showLoader } from '../../store/reducers/appSlice';
import { VerifyIcon } from '../../components/icons/verify-icon';
import { OtpInput } from '../../components/otp-input/otp-input';
import { updateLoginStatus } from '../../store/reducers/userSlice';
import { openToast } from '../../store/reducers/toastSlice';
import { useTranslation } from 'react-i18next';
import { CognitoCallbacks, CognitoErrorCodes } from '../../utils/application-constants';

export const VerifyLoginOtp = () => {
    const timerValueInSec = 3 * 60;
    const navConfig: INavigationConfig = NavigationConfig;
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [otpValue, setOtpValue] = useState<string>('');
    const [timer, setTimer] = useState<number>(timerValueInSec);
    const [isResendEnabled, setIsResendEnabled] = useState<boolean>(false);
    const [isValidOtp, setIsValidOtp] = useState<boolean>(true);
    const [invalidOtpText, setInvalidOtpText] = useState<string>('');
    const { username, password, type } = useLocation().state;

    const { t } = useTranslation();
    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else {
            setIsResendEnabled(true);
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (isValidOtp && otpValue.length === 6) {
            console.log(otpValue, 'otp in handleOtpChange');
            if (type === CognitoCallbacks.CONFIRM_RESET_PASSWORD_WITH_CODE) {
                verifyConfirmOtp();
            } else {
                verifyOtp();
            }
        }
    }, [otpValue]);

    const handleOtpChange = (otp: string, length: number) => {
        setOtpValue(otp);
        if (otp?.length === length) {
            setIsValidOtp(true);
        }
    };
    const resendOtp = async () => {
        setIsValidOtp(false);
        setTimer(timerValueInSec);
        setIsResendEnabled(false);
        try {
            dispatch(showLoader());
            const user = await signIn({ username: username, password: password });
            switch (user?.nextStep?.signInStep) {
                case CognitoCallbacks.CONFIRM_SIGN_IN_WITH_SMS_CODE:
                case CognitoCallbacks.CONFIRM_SIGN_IN_WITH_EMAIL_CODE:
                    dispatch(
                        openToast({
                            show: true,
                            message: t('otp_sent_to_registered_email_message'),
                            severity: 'success',
                            isAlertOpen: true,
                        }),
                    );
                    break;
            }
        } catch (err: any) {
            console.log(err, 'err');
            switch (err?.name) {
                case CognitoErrorCodes.EMPTY_SIGNIN_USER_NAME:
                    dispatch(
                        openToast({
                            show: true,
                            message: t('empty_sign_in_exception_message'),
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
                    navigate(navConfig.Login);
                    break;
                default:
                    dispatch(
                        openToast({
                            show: true,
                            message: err?.name,
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
            }
        } finally {
            dispatch(hideLoader());
        }
    };
    const verifyOtp = async () => {
        setTimer(0);
        setIsResendEnabled(true);
        try {
            dispatch(showLoader());
            const res = await confirmSignIn({ challengeResponse: otpValue?.toString() });
            if (res.nextStep.signInStep === CognitoCallbacks.DONE) {
                const user = await cognitoUserPoolsTokenProvider.authTokenStore.loadTokens();
                if (user) {
                    dispatch(
                        setSession({
                            token: user?.idToken?.toString(),
                            payload: user?.idToken?.payload,
                        }),
                    );
                    navigate(navConfig.Home);
                    dispatch(updateLoginStatus(true));
                }
            }
        } catch (err: any) {
            console.log(err, '==> err');
            switch (err?.name) {
                case CognitoErrorCodes.CODE_MISMATCH_EXCEPTION:
                    dispatch(
                        openToast({
                            show: true,
                            message: t('otp_invalid_message'),
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
                    break;
                case CognitoErrorCodes.NOT_AUTHORIZED_EXCEPTION:
                    dispatch(
                        openToast({
                            show: true,
                            message: t('otp_expired_message'),
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
                    break;
                case CognitoErrorCodes.SIGN_IN_EXCEPTION:
                    dispatch(
                        openToast({
                            show: true,
                            message: t('sign_in_exception_message'),
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
                    break;
                default:
                    dispatch(openToast({ show: true, message: err?.name, severity: 'error', isAlertOpen: true }));
            }
        } finally {
            dispatch(hideLoader());
        }
    };

    const navigateBack = () => {
        switch (type) {
            case CognitoCallbacks.CONFIRM_SIGN_IN_WITH_EMAIL_CODE:
            case CognitoCallbacks.CONFIRM_SIGN_IN_WITH_SMS_CODE:
                navigate(navConfig.Login);
                break;
            default:
                navigate(-1);
        }
    };
    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs.toString().padStart(2, '0')}`;
    };

    const verifyConfirmOtp = async () => {
        setTimer(0);
        setIsResendEnabled(true);
        try {
            dispatch(showLoader());

            await confirmResetPassword({
                username: username,
                confirmationCode: otpValue.toString(),
                newPassword: password,
            });

            // Success toast
            dispatch(
                openToast({
                    show: true,
                    message: t('password_reset_success_message'),
                    severity: 'success',
                    isAlertOpen: true,
                }),
            );
            navigate(navConfig.Login); // Navigate on success
        } catch (err: any) {
            let message = t('unexpected_error_message'); // Default message

            // Determine the error message based on the error name
            switch (err?.name) {
                case CognitoErrorCodes.CODE_MISMATCH_EXCEPTION:
                    message = t('otp_invalid_message');
                    break;
                case CognitoErrorCodes.NOT_AUTHORIZED_EXCEPTION:
                    message = t('otp_expired_message');
                    break;
                case CognitoErrorCodes.SIGN_IN_EXCEPTION:
                    message = t('sign_in_exception_message');
                    break;
                default:
                    message = err?.name || t('unexpected_error_message');
            }

            // Dispatch the error toast with the resolved message
            dispatch(
                openToast({
                    show: true,
                    message: message,
                    severity: 'error',
                    isAlertOpen: true,
                }),
            );
        } finally {
            dispatch(hideLoader());
        }
    };

    return (
        <LoginUi container spacing={{ sm: 0, lg: 2 }}>
            <Grid2
                className="imgContainer"
                size={{ sm: 12, lg: 8 }}
                display={{ xs: 'none', lg: 'flex' }}
                alignItems={'center'}
            >
                <Box />
            </Grid2>
            <Grid2
                size={{ sm: 12, lg: 4 }}
                className="feildsLabels"
                position={'relative'}
                minWidth={{ xs: '100%', lg: 'auto' }}
            >
                <IconButton className="backBtn" onClick={navigateBack}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Box height={'100%'}>
                    <Box
                        pt={3}
                        px={5}
                        pb={2}
                        height={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                    >
                        <Box textAlign={'center'} className="logo otpLogo">
                            <VerifyIcon />
                        </Box>
                        <OtpInput
                            isValidOtp={isValidOtp}
                            showText={true}
                            type={'password'}
                            autoFocus={true}
                            length={6}
                            onChange={handleOtpChange}
                        />
                        <Typography variant="h4" pt={1.5} textAlign={'center'}>
                            {t('otp_verification_title')}
                        </Typography>
                        <Typography variant="h6" py={1.5} textAlign={'center'} color="info">
                            {t('enter_otp_received_message')}
                        </Typography>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} pb={1.5}>
                            <Typography variant="subtitle1" py={1.5} textAlign={'center'} color={colors.gray}>
                                {t('otp_not_recieved_message')}
                            </Typography>
                            <ButtonComponent
                                className="cstResendBtn"
                                variant="text"
                                size="medium"
                                buttonName={t('otp_resend_button_title')}
                                disabled={!isResendEnabled}
                                onClick={isResendEnabled ? resendOtp : void 0}
                            />
                            <Typography variant="h6" className="timer">
                                {timer ? formatTime(timer) : ''}
                            </Typography>
                        </Stack>
                    </Box>
                </Box>
            </Grid2>
        </LoginUi>
    );
};
