import { Box, styled } from "@mui/material";

export const TextFieldWrapper = styled(Box)(({ theme }) => ({
  "& .MuiFormControl-root": {
    width: "100%",
    marginBottom: theme.spacing(1.45),
    "& .MuiFormLabel-root": {
      fontSize: theme.spacing(2),
      // color: theme.palette.grey[300],
      "&.MuiInputLabel-shrink": {
        fontWeight: 600,
        lineHeight: theme.spacing(3),
      },
      "&.Mui-focused": {
        // color: theme.palette.text.primary,
      },
    },
    '& .MuiInputBase-root': {
      // color: theme.palette.common.white,
      position: 'relative',
      '&.MuiInputBase-colorSecondary': {
        '& .MuiOutlinedInput-notchedOutline': {
          // borderColor: theme.palette.common.white,
        },
        '& .MuiInputAdornment-root': {
          cursor: 'pointer',
          '& .icon-eye_on': {
            '&::before': {
              fontSize: theme.spacing(1.75),
              // color: theme.palette.grey[300]
            }
          },
          '& .icon-eye-off': {
            '&::before': {
              fontSize: theme.spacing(2.25),
              // color: theme.palette.grey[300]
            }
          }
        }
      },
      '& input': {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 1000px rgb(32, 18, 2) inset",
          WebkitTextFillColor: "#fff !important",
        },
       
      }
    }
  },
}));
