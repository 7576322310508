import { Routes, Route, Navigate } from 'react-router-dom';
import { NavigationConfig } from '../utils/navigation-constants';
import { PageNotFound } from '../pages/page-not-found';
import { Home } from '../pages/home/index';
import { ProfileSetting } from '../pages/profile-setting';
import { NotificationSetting } from '../pages/notification-setting';
import { HeaterDetails } from '../pages/heater-details';
export const PostAuthenticatedRoutes = () => {
    return (
        <Routes>
            <Route path={NavigationConfig.Home} element={<Home />} />
            <Route path={NavigationConfig.ProfileSetting} element={<ProfileSetting />} />
            <Route path={NavigationConfig.NotificationSetting} element={<NotificationSetting />} />
            <Route path={NavigationConfig.NotificationSetting} element={<NotificationSetting />} />
            <Route path={NavigationConfig.HeaterDetails} element={<HeaterDetails />} />
            <Route path="/" element={<Navigate to={NavigationConfig.Home} />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};
