import { onCLS, onFCP, onLCP, onTTFB, MetricType } from 'web-vitals';
type VitalsCallback = (metric: MetricType) => void;

// Function to report web vitals
const reportWebVitals = (onPerfEntry?: VitalsCallback): void => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        onCLS(onPerfEntry); // Cumulative Layout Shift
        onFCP(onPerfEntry); // First Contentful Paint
        onLCP(onPerfEntry); // Largest Contentful Paint
        onTTFB(onPerfEntry); // Time to First Byte
    }
};

export { reportWebVitals };
