import { Box, styled } from "@mui/material";

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  "& .MuiButtonBase-root.MuiButton-root": {
    // borderRadius: theme.spacing(0.25),
    // fontSize: theme.spacing(2),
    // fontWeight: 700,
    // lineHeight: theme.spacing(2.85),
    // letterSpacing: 1,
    // padding: theme.spacing(1.75, 0),
    "&.MuiButton-sizeSmall": {
      padding: theme.spacing(1.25, 3),
      height: theme.spacing(4.5),
    },
    "&.MuiButton-outlined": {

      padding: theme.spacing(1.25, 2),
      borderRadius: "4px",
      "&:hover": {
      },
    },
  },
}));
