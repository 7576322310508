import React from "react";
import { Avatar, Box, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography, useColorScheme, useMediaQuery } from "@mui/material";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import ContrastOutlinedIcon from '@mui/icons-material/ContrastOutlined';
import { HeaderStyles } from "./header.style";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { StyleWrapper } from "../../pages/home/home-styles";
import { colors, theme } from '../../theme';
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from "react-i18next";


interface Iprops {
  openMenuClick: any;
}
const HeaderBar: React.FC<Iprops> = ({ openMenuClick }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const { mode, setMode } = useColorScheme();
  const { t } = useTranslation()
  if (!mode) {
    return null;
  }
  const handleChange = (event: any, newMode: any) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };
  const miniAnalytics = (icon: any, header: string, headerName: string) => {
    return (
      <Stack direction={'row'} alignItems={'center'} spacing={{ xs: 1, lg: 2 }}>
        {icon}
        <Box>
          <Typography variant='h6' color='info.main'>{header}</Typography>
          <Typography variant='h5' fontWeight={700}>{headerName}</Typography>
        </Box>
      </Stack>
    )
  }

  return (
    <>
      <HeaderStyles py={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        {matches && <IconButton onClick={openMenuClick}><MenuOutlinedIcon /></IconButton>}
        <Box display={{ xs: 'none', sm: 'block' }}>
          <Typography variant="h4">{t('good_morning')}, John Smith!</Typography>
          <Typography variant="h6">{t('quote1')}</Typography>
        </Box>
        <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
          <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={handleChange}
            aria-label="text alignment"
          >
            <ToggleButton value="light" aria-label="light theme">
              <LightModeOutlinedIcon />
            </ToggleButton>
            <ToggleButton value="dark" aria-label="dark theme">
              <DarkModeOutlinedIcon />
            </ToggleButton>
            <ToggleButton value="system" aria-label="system theme">
              <ContrastOutlinedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <IconButton><NotificationsNoneOutlinedIcon /></IconButton>
          <Avatar sizes="small" sx={{ display: { xs: 'none', sm: 'flex' } }}>N</Avatar>
        </Stack>
      </HeaderStyles>
      <StyleWrapper>
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'}>
          <Box>
            <Typography variant='h4'>{t('dashboard')}</Typography>
            <Typography variant='h6' display={'flex'} alignItems={'center'} color='info'> {t('heater')}-1 <ArrowDropDownIcon /></Typography>
          </Box>
          <Stack direction={'row'} spacing={{ xs: 1, lg: 3 }} justifyContent={'space-between'}>
            {miniAnalytics(<ElectricalServicesOutlinedIcon sx={{ color: colors.lightBlue }} />, t('power'), t('on'))}
            {miniAnalytics(<WifiOutlinedIcon sx={{ color: colors.lightRed }} />, t('internet'), t('off'))}
            {miniAnalytics(<EmojiEventsOutlinedIcon />, t('score'), '750/1000')}
          </Stack>
        </Stack>
      </StyleWrapper>
    </>
  );

};
export { HeaderBar };
