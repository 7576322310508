import { Box, Slider, Stack, styled, Typography, useTheme } from "@mui/material"
import { ListItemWrapper, SliderUI } from "./home-styles"
import { colors } from "../../theme"
import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined';
import React from "react";
import { useTranslation } from "react-i18next";
const marks = [
    { value: 0, label: 'Low' },
    { value: 50, label: 'Medium' },
    { value: 100, label: 'High' },
];

export const Temparature = () => {
    // const cstTheme = useTheme()
    const { t } = useTranslation()
    const [value, setValue] = React.useState(50);
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };
    return (
        <ListItemWrapper p={2} className='listItems' bgcolor={'info.50'} boxShadow={1} borderRadius={4} mt={2}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='h5' display={'flex'} alignItems={'center'} columnGap={1.5}><DeviceThermostatOutlinedIcon /> {t('temperature')}</Typography>
                <Typography variant='subtitle1' fontWeight={700} color={colors.darkYellow}>{value === 0 ? 'Low' : value === 50 ? 'Medium' : 'High'}</Typography>
            </Box>
            <Stack pt={3.5} direction={'row'} justifyContent={'space-between'}>
                <SliderUI
                    value={value}
                    onChange={handleChange}
                    step={null}
                    marks={marks}
                    min={0}
                    max={100}
                />
            </Stack>
        </ListItemWrapper>
    )
}