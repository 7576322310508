import * as Yup from 'yup';
export const passwordSetupValidationSchema = (t: any) =>
    Yup.object({
        password: Yup.string()
            .required(t('new_password_required'))
            .min(8, t('password_min_length'))
            .max(20, t('password_max_length'))
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^\$\*\.\[\]\{\}\(\)\?\-"!@#%&\/\\,><':;|_~`+=])(?=.{8,20}$)/,
                t('password_not_matched'),
            ),
        confirmPassword: Yup.string()
            .required(t('confirm_password_required'))
            .oneOf([Yup.ref('password')], t('password_not_matched_confirm')),
    });
