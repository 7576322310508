import { Box, Slider, Stack, styled } from "@mui/material";
import { colors } from "../../theme";
const assetLoader = require("../../assets/images/heater.png");

export const ListItemWrapper = styled(Stack)(({ theme }) => ({
    // '& .listItems': {
    // borderRadius: theme.spacing(2),
    // boxShadow: '0px 0px 24px 0px #5C5C5C1F',
    // }
}));
export const StyleWrapper = styled(Stack)(({ theme }) => ({
    '& .indexBody': {
        '&.indexBodyLoader': {
            height: 'calc(100dvh - 155px)',
            [theme.breakpoints.down('sm')]: {
                height: 'auto'
            },
        },
        '& .listBlock': {
            height: 'calc(100dvh - 155px)',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                height: 'calc(100dvh - 190px)'
            },
        },
        '& .assetBlock': {
            '& .MuiBox-root': {
                backgroundImage: `url(${assetLoader})`,
                width: '100%',
                height: '97%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPositionX: 'center'
            }
        }
    }
}));

export const SliderUI = styled(Slider)(({ theme }) => ({
    marginBottom: `${theme.spacing(0)}`,
    width: '98%',
    '& .MuiSlider-rail,& .MuiSlider-track': {
        opacity: 1,
        background: 'linear-gradient(90deg, #2FABE1 0%, #59BDE7 5.13%, #89D1ED 11.85%, #A8DEF1 16.98%, #B3E3F3 19.87%, #FDCC3F 40.5%, #FF8524 61.36%, #E03C23 80.8%, #441212 100%)',
    },
    '& .MuiSlider-markLabel': {
        transform: 'translate(-50%, -51px)',
        fontSize: theme.spacing(1.5),
        '&:nth-child(4)': {
            left: '4% !important'
        },
        '&:nth-child(6)': {
            left: '52% !important'
        },
        '&:nth-child(8)': {
            left: '96% !important'
        }
    },
    '& .MuiSlider-thumb': {
        '&:before': {
            background: 'linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%)',
            border: '0.72px solid #282B2E',
            boxShadow: [2]
        },
        '&:after': {
            width: theme.spacing(1),
            height: theme.spacing(1),
            background: 'linear-gradient(320.66deg, #FDCC3F 14.75%, #FF8524  84.81%)',
        },
        transform: 'translate(-8%, -50%)'
    }
}))