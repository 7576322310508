import React from "react";
import { LandingPage } from './pages/landing-page';
import { CssBaseline, ThemeProvider } from '@mui/material';
import './fonts.css';
import { theme } from './theme';
import { Amplify } from 'aws-amplify';
import { Toast } from "./components/toast/toast";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import { useDispatch } from "react-redux";
import { openToast, resetToast } from "./store/reducers/toastSlice";
import { Loader } from "./components/loader/loader";
import './translations/i18n';
import { Hub } from "aws-amplify/utils";
import { getCurrentUser } from "aws-amplify/auth";
import { updateLoginStatus } from "./store/reducers/userSlice";
import { hideLoader, showLoader } from "./store/reducers/appSlice";
import { environment } from "./environments/environment";
import SocialLoginAuthCallback from "./hooks/social-login-callback";

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: environment.USER_POOL_CLIENT_ID || '',
            userPoolId: environment.USER_POOL_ID || '',
            identityPoolId: environment.IDENTITY_POOL_ID || '',
            loginWith: {
                oauth: {
                    domain: environment.OAUTH_DOMAIN || '',
                    scopes: ['email', 'openid', 'profile'],
                    redirectSignIn: (environment.REDIRECT_SIGN_IN_URLS || '')
                    .split(',') // Split the string into an array
                    .map(url => url.trim()) // Remove extra spaces
                    .filter(Boolean), // Remove empty strings
                    redirectSignOut: [environment.REDIRECT_SIGN_OUT_URLS || ''],
                    responseType: 'code', // Use 'token' for implicit flow
                }
            },
        },
    },
});
const App: React.FC = () => {
    const showToast = useSelector((state: RootState) => state.toast);
    let dispatch = useDispatch();
    SocialLoginAuthCallback();
    React.useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({ payload }) => {
          switch (payload.event) {
            case "signInWithRedirect":
              getUser();
              break;
          }
        });
        getUser();
        return unsubscribe;
      }, []);

    const getUser = async () => {
        let currentUser;
        try {
            dispatch(showLoader())
            currentUser = await getCurrentUser();
        } catch (err: any){
            console.log(err);
            console.log("Not signed in");
        }finally{
            dispatch(hideLoader())
            if(currentUser?.userId || currentUser?.username){
                dispatch(updateLoginStatus(true))
            }
        }
    };
    const closeToast = () => {
        dispatch(resetToast({ show: false, message: null, isAlertOpen: false }));
    }
    const { activeHTTPRequests } = useSelector(
        (state: any) => state.app
    );
    return (
        <div className="app">
            {activeHTTPRequests >= 1 && <Loader />}
            <Toast
                open={showToast?.show}
                message={showToast?.message}
                severity={showToast?.severity}
                handleClose={() => {
                    closeToast();
                }}
            />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <LandingPage />
            </ThemeProvider>
        </div>
    );
};

export { App };
