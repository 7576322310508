import { Grid2, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors, theme } from "../../theme";
const SplashLogo = require('../../assets/images/splash-image.png');

const dividerMixin = (deg: number, leftValue: any, rightValue: any) => ({
    content: "''",
    height: '1px',
    width: '25%',
    top: '12px',
    border: '1px solid transparent',
    position: 'absolute',
    borderImage: theme.palette.mode === 'dark' ? `linear-gradient(-${deg}deg, ${colors.blackColor} 0%, ${colors.white} 100%) 1` : `linear-gradient(${deg}deg, ${colors.blackColor} 0%,  ${colors.white} 100%) 1`,
    left: leftValue,
    right: rightValue
});

export const useStyles = makeStyles({
    signInpopup: {
        "& .MuiPaper-root": {
            backgroundColor: 'rgba(32, 18, 2, 0.67)',
            margin: 0,
            padding: '24px',
            fontSize: theme.spacing(2),
            '& .MuiTypography-h6': {
                padding: 0,
                marginBottom: '16px',
                '& .MuiButtonBase-root': {
                    fontSize: '15px',
                    color: '#6B7280'
                }
            },
            '& .MuiDialogContent-root': {
                padding: 0,
                '& .link': {
                    '& a': {
                        padding: '0px 3px',
                        textDecoration: 'none',
                    }
                }
            }
        },
    }
})

export const LoginUi = styled(Grid2)(({ theme }) => ({
    height: '100dvh',
    '&  .imgContainer': {
        overflow: 'hidden',
        height: '100dvh',
        '& .MuiBox-root': {
            width: '100%',
            height: '100%',
            background: `url(${SplashLogo}) no-repeat`,
            backgroundSize: 'cover'
        }
    },
    '& .feildsLabels': {
        '& .logo': {
            '& svg': {
                fill: theme.palette.mode === 'dark' ? colors.white : colors.blackColor
            },
            '&.otpLogo': {
                '& svg': {
                    width: theme.spacing(7.5),
                    fill: colors.blackColor
                },
            },
            '&.passwordLogo': {
                '& svg': {
                    width: theme.spacing(18.5),
                    fill: 'none',
                    '& path': {
                        stroke: theme.palette.mode === 'dark' ? colors.white : colors.blackColor
                    }
                },
            },
            '&.forgotIcon': {
                '& svg': {
                    '& path': {
                        stroke: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
                        fill: 'none'
                    }
                },
            }
        },
        '& .MuiFormControl-root': {
            '& .MuiInputBase-root': {
                height: 'auto',
                padding: theme.spacing(0.5, 0),
                borderRadius: 0,
                fontFamily: 'good_sansregular'
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                borderBottom: `1px solid ${theme.palette.mode === 'dark' ? colors.white : colors.blackColor}}`
            }
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '12px'
        },
        '& .cstBtn': {
            background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightBgButton,
            padding: theme.spacing(2, 0),
            boxShadow: '6px 6px 16px 0px #00000040',
            letterSpacing: '1.25px'
        },
        '& .divider': {
            '& .MuiTypography-h5': {
                position: 'relative',
                '&:before': {
                    ...dividerMixin(270, '19%', 'auto'),
                },
                '&:after': {
                    ...dividerMixin(90, 'auto', '21%'),
                }
            }
        },
        '& .mediaSlot': {
            '& .MuiIconButton-root': {
                width: theme.spacing(6.25),
                height: theme.spacing(6.25),
                background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightBgButton,
                '& svg': {
                    fill: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
                }
            }
        },
        '& .cstCheckbox': {
            '& svg': {
                '& path': {
                    fill: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
                }
            }
        },
        '& .cstResendBtn': {
            color: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
        },
        '& .backBtn': {
            position: 'absolute',
            top: theme.spacing(2.5),
            background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightBgButton,
            '& svg': {
                color: colors.info
            }
        },
        '& .cstList': {
            '& .MuiList-root': {
                listStyleType: 'disc',
                padding: theme.spacing(1, 4),
                '& .MuiListItem-root': {
                    display: 'list-item',
                    padding: theme.spacing(0, 1),
                }
            }
        },
        '& .forgot-btn': {
            fontSize: theme.spacing(1.5),
            fontFamily: 'good_sansregular',
            textTransform: 'capitalize',
            letterSpacing: 0,
            color: theme.palette.mode === 'dark' ? colors.white : colors.blackColor
        },
        '& .actionItems':{
            '& .cstBtn':{
                boxShadow: theme.palette.mode === 'light' && 'none'
            }
        }
    },
    '& .timer': {
        minWidth: theme.spacing(4.375),
        fontFamily: 'good_sansmedium !important',

    }
}));
