import { revertAll } from "../actions";
import { createSlice } from '@reduxjs/toolkit';

export interface IToastState {
    show: boolean;
    isAlertOpen: boolean;
    message: string;
    severity: string;
};

const initialState: IToastState = {
    show: false,
    isAlertOpen: false,
    message: '',
    severity: 'success',
};

const toastSlice = createSlice({
    name: 'toast',
    initialState: initialState,
    reducers: {
        openToast: (state, action) => {
            state.show = action.payload.show;
            state.isAlertOpen = action.payload.isAlertOpen;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
        },
        resetToast: (state, action) => {
            state.isAlertOpen = action.payload.isAlertOpen;
            state.show = action.payload.show;
            state.message = action.payload.message;
        },
    },
    extraReducers: builder => builder.addCase(revertAll, () => initialState),
});

export const { openToast, resetToast } = toastSlice.actions;
export const toastReducer = toastSlice.reducer;